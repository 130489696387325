import * as contentful from 'contentful'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(() => {
  const contentfulConfig = {
    space: '5hagb7tu247j',
    accessToken: '511ce7e2d84c94d6cee15b416ff7ba3269fd1f2c4a4b80192888d9ff07780daf',
  }

  const createClientFunc =
    process.env.NODE_ENV === 'development' ? contentful.createClient : contentful.createClient

  return {
    provide: {
      client: createClientFunc(contentfulConfig),
    },
  }
})
