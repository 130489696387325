
import * as cloudinaryRuntime$zSHVef9ETA from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.7.0/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as cmsProviderRuntime$Wd96LQCkMd from '/opt/render/project/src/apps/refresh/providers/cms.js'
import * as ipxRuntime$tImO0WrnW2 from '/opt/render/project/src/node_modules/.pnpm/@nuxt+image@1.7.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1920,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$zSHVef9ETA, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/upload"} },
  ['cmsProvider']: { provider: cmsProviderRuntime$Wd96LQCkMd, defaults: {"baseURL":"https://res.cloudinary.com/klandestino-ab/image/fetch"} },
  ['ipx']: { provider: ipxRuntime$tImO0WrnW2, defaults: {} }
}
        